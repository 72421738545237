$btn-bg-color: #e8e8e9;
$btn-bg-primary: #3498db;

.modal {
  display: flex;
  flex-direction: column;
  gap: 1em;
  max-height: 60vh;
}

.header {
  display: flex;
  justify-content: space-between;
}

.main {
  flex: 1 1 auto;
  min-height: 0;
  overflow: auto;
  display: flex;
  flex-wrap: wrap;
}

.footer {
  display: flex;
  justify-content: flex-end;
  gap: 1em;
}

.btn {
  outline: none;
  border: none;

  padding: 0.5em 1em;
  border-radius: 3px;
  cursor: pointer;
}

.btn-default {
  @extend .btn;
  background-color: $btn-bg-color;

  &:hover {
    background-color: darken($btn-bg-color, 10);
  }
  &:active {
    background-color: darken($btn-bg-color, 20);
  }
}

.btn-primary {
  @extend .btn;
  color: white;
  background-color: $btn-bg-primary;

  &:hover {
    background-color: darken($btn-bg-primary, 10);
  }
  &:active {
    background-color: darken($btn-bg-primary, 20);
  }
}
