@use "/styles/base";

.page {
  min-width: 960px;
  display: flex;
  flex-direction: column;
  min-height: 100%;
}

.header {
  display: flex;
  gap: 1em;
  padding: 0.5em 1em;
  color: white;
  background-color: base.$color-primary;

  .logo {
    height: 2.5em;
  }

  .nav {
    flex: 1 0 auto;
    display: flex;
    align-items: center;
    gap: 1em;

    a {
      text-decoration: none;
    }

    .active-link {
      color: white;
      font-weight: bold;
    }

    .link {
      color: rgba(white, 0.9);
    }
  }

  .member {
    display: flex;
    align-items: center;

    .logout {
      padding: 0.5em 1em;
      margin: 0 0.5em;
    }
  }
}

.main {
  flex: 1 1 auto;
}

.footer {
  // color: white;
  // background-color: base.$color-primary;
  // text-align: center;
  // margin-top: 1em;
  // padding: 1em;
  // position: fixed;
  // width: 100%;
  // bottom: 0;
  // z-index: 99;
  .content {
    color: white;
    background-color: base.$color-primary;
    text-align: center;
    padding: 1em;
    width: 100%;
  }
}
