@use "/styles/base";

.error {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb;
  font-size: 0.85em;
  padding: 0.5em 1em;
}

.container {
  margin: 7.5em auto 0;
  width: 28em;

  .logo {
    display: block;
    margin: 0.25rem auto;
  }

  .title {
    margin: 1em auto;
    color: base.$color-primary;
    font-size: 1.5em;
    font-weight: bold;
    text-align: center;
  }

  .box {
    display: flex;
    flex-direction: column;
    gap: 1em;
    background-color: #fff;
    border: 1px solid rgba(0, 0, 0, 0.125);
    border-radius: 0.25rem;
    padding: 1em;
  }

  .button {
    width: 100%;
    padding: 0.5em 1em;
    font-size: 1em;
    letter-spacing: 0.5em;
    color: white;
    background-color: base.$color-primary;
    border-color: base.$color-primary;

    &:hover {
      background-color: darken(base.$color-primary, 5%);
    }

    &:not(:disabled):active {
      background-color: darken(base.$color-primary, 10%);
    }
  }
}

.form-group {
  display: flex;
  flex-direction: column;
  gap: 0.25em;

  .input-group {
    display: flex;
    align-items: center;
    border: 1px solid rgba(0, 0, 0, 0.125);

    .prepend {
      padding: 0.75em;
      background-color: #e9ecef;
    }

    .form-control {
      flex: 1 1 auto;
      align-self: stretch;
      padding: 0.5em;
      border: none;
    }
  }
}
