$width: 113px;

.item,
.add-item {
  position: relative;
  display: block;
  width: $width;
  height: $width;
  margin: 5px;
  overflow: hidden;
  border-radius: 3px;
  transition: all 300ms ease-out;
}

.item {
  background: center / cover no-repeat none;

  &:hover::after,
  &.selected::after {
    content: "";
    position: absolute;
    inset: 0;
    z-index: 1;
    background-color: rgba(52, 152, 219, 0.3);
  }

  &.selected:hover:after {
    background-color: rgba(52, 152, 219, 0.5);
  }

  &.selected::before {
    content: "\2713";
    position: absolute;
    z-index: 2;
    color: #fff;
    font-size: 48px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.add-item {
  background-color: grey;

  &:hover {
    background-color: darken(grey, 10);
  }

  .label {
    position: absolute;
    inset: 0;
    cursor: pointer;
  }

  .input {
    position: absolute;
    opacity: 0;
  }

  .plus {
    position: absolute;
    cursor: pointer;
  }
}
