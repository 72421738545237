$form-label-width: 10em;

.form-row {
  display: flex;
  justify-content: flex-start;
  gap: 1em;
  margin: 0.5em 0;

  .label {
    flex: 0 0 $form-label-width;
    padding: 0.25rem 0;
  }

  .content {
    display: flex;

    .text {
      padding: 0.25rem 0;

      display: flex;
      align-items: center;
    }

    .error {
      font-size: 0.85em;
      color: red;
      margin-left: 0.5em;
    }
  }

  input,
  textarea,
  select {
    padding: 0.25rem;
  }

  input,
  textarea {
    min-width: 25em;
  }

  input[type="checkbox"],
  input[type="radio"] {
    min-width: unset;
  }

  textarea {
    min-height: 5em;
  }
}
