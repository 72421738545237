.main {
  display: flex;
  flex-direction: column;
  gap: 3em;
  margin: 0.5em 0;

  .header {
    display: flex;
    gap: 1em;

    .title {
      font-size: 1.5em;
    }
  }

  .empty {
    font-size: 1.2em;
    // min-height: 5em;
    // display: flex;
    // justify-content: center;
    // align-items: center;
  }

  ul.content {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  li {
    line-height: 2;
    display: flex;
    align-items: center;

    border-bottom: 1px solid grey;

    &.active,
    &:hover {
      font-size: 1.1em;
      color: blue;
      font-weight: bolder;
    }
  }

  .remove {
    cursor: pointer;
    font-size: 0.85em;
    // padding: 0 1em;
    margin-left: 3em;
    padding: 0 0.5em;
    // margin is better
    // padding-left: 3em;
    // visibility: hidden;
    display: none;
  }

  li:hover .remove {
    // visibility: visible;
    display: block;
  }
}
